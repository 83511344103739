// import React from 'react';
// import './../BottomSheet.css'; // Import your CSS file for styling

// const BottomSheet = ({ isOpen, onClose, stock }) => {
//   return (
//     <div className={`bottom-sheet ${isOpen ? 'open' : ''}`}>
//       <div className="bottom-sheet-header">
//         <h3>{stock.dispname}</h3>
//         <button onClick={onClose}>Close</button>
//       </div>
//       <div className="bottom-sheet-body">
//         {/* Stock details */}
//         <div className="stock-details">
//           <p>Last Price: {stock.lastPrice}</p>
//           <p>Change: {stock.change}</p>
//           {/* Add more details as needed */}
//         </div>

//         {/* Buy/Sell Depth */}
//         <div className="buy-sell-depth">
//           {/* Display buy and sell depth information */}
//         </div>

//         {/* Buy/Sell buttons */}
//         <div className="buy-sell-buttons">
//           <button className="buy-button">Buy</button>
//           <button className="sell-button">Sell</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BottomSheet;



// import React from 'react';
// import { useSpring, animated } from 'react-spring';
// import { Box, Typography, Button, IconButton, Grid } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import './../BottomSheet.css';

// const BottomSheet = ({ stock, isOpen, onClose }) => {
//   const styles = useSpring({
//     transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
//   });

//   return (
//     <animated.div style={styles} className="bottom-sheet">
//       <Box className="bottom-sheet-header">
//         <Typography variant="h6">
//           {stock.dispname} - {stock.Symbol}
//         </Typography>
//         <IconButton onClick={onClose}>
//           <CloseIcon />
//         </IconButton>
//       </Box>
//       <Box className="bottom-sheet-content">
//         <Typography variant="h4" className="stock-price">
//           ₹{stock.LTP}
//         </Typography>
//         <Typography variant="body2" className="stock-change">
//           {stock.Change} ({stock.Change_per}%)
//         </Typography>
//         <Grid container spacing={2} className="stock-details">
//           <Grid item xs={6}>
//             <Typography>Open</Typography>
//             <Typography>{stock.Open}</Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>High</Typography>
//             <Typography>{stock.High}</Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>Low</Typography>
//             <Typography>{stock.Low}</Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography>Prev. Close</Typography>
//             <Typography>{stock.Prev_Close}</Typography>
//           </Grid>
//         </Grid>
//         <Box className="order-depth">
//           <Typography variant="body1">Buy/Sell Depth</Typography>
//           {/* Add your buy/sell depth table here */}
//         </Box>
//         <Box className="bottom-actions">
//           <Button variant="contained" color="primary">
//             Buy
//           </Button>
//           <Button variant="contained" color="secondary">
//             Sell
//           </Button>
//         </Box>
//       </Box>
//     </animated.div>
//   );
// };

// export default BottomSheet;





import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSpring, animated } from 'react-spring';
import { Switch, FormControlLabel, Box, Typography, Button, IconButton, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import upIcon from '../assets/up_icon.png';
import downIcon from '../assets/down_icon.png';
import './../BottomSheet.css';
import { useDrag } from 'react-use-gesture';
import swal from 'sweetalert';
import { WebSocketContext } from "../WebSocketContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from './CustomToast';
import { blue } from '@mui/material/colors';

const BottomSheet = ({ userDetails, stock, isOpen, onClose }) => {
  const { refreshTradesAndPortfolio, submitRejectionLog } = useContext(WebSocketContext);
  const [disableBtn, setDisable] = useState(false);
  const styles = useSpring({
    transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
  });

  // console.log("BottomSheet",stock);

  const showToast = (type, message) => {
    if(type==1){
      toast.success(<CustomToast title={"Trade Placed"} description={message} />, {
          position:'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
    else if(type==2){
      toast.success(<CustomToast title={"Order Placed"} description={message} />, {
          position:'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
    else if(type==3){
      toast.error(<CustomToast title={"Order Failed"} description={message} />, {
          position:'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
    }
};

  const [{ y }, api] = useSpring(() => ({ y: 0 }));

  React.useEffect(() => {
    api.start({ y: isOpen ? 0 : 100 });
  }, [isOpen, api]);

  // const bind = useDrag(
  //   ({ down, movement: [, my], direction: [, dy], velocity }) => {
  //     if (down && dy > 0 && velocity > 0.2) {
  //       onClose();
  //     } else {
  //       api.start({ y: down ? my : 0 });
  //     }
  //   },
  //   { from: () => [0, y.get()] }
  // );

  const [isDepthExpanded, setIsDepthExpanded] = useState(true);
  const [lots, setLots] = useState(1);
  const [quantity, setQuantity] = useState(stock.lotsize || 1);
  const [orderType, setOrderType] = useState('market');
  const [isLimitOrder, setIsLimitOrder] = useState(false);
  const [price, setPrice] = useState('');

  const toggleDepth = () => {
    setIsDepthExpanded(!isDepthExpanded);
  };

  const prevLotsRef = useRef(lots);
  const prevQuantityRef = useRef(quantity);

  useEffect(() => {
    if (isOpen) {
      // Initialize values based on the stock's lot size when the bottom sheet opens
      const initialLotSize = stock.lotsize || 1;
      setQuantity(initialLotSize);
      setLots(1);
      prevLotsRef.current = 1;
      prevQuantityRef.current = initialLotSize;
    }
  }, [isOpen, stock.lotsize]);

  useEffect(() => {
    console.log("Lots changed.");
    const newQuantity = lots * (stock.lotsize || 1);
    if (prevLotsRef.current !== lots) {
      setQuantity(newQuantity);
      prevLotsRef.current = lots;
      prevQuantityRef.current = newQuantity;
    }
  }, [lots, stock.lotsize]);

  useEffect(() => {
    // const newLots = Math.ceil(quantity / (stock.lotsize || 1));
    console.log("Quantity: ",quantity);
    const newLots = quantity / (stock.lotsize || 1);
    if (prevQuantityRef.current !== quantity) {
      setLots(newLots);
      prevQuantityRef.current = quantity;
      prevLotsRef.current = newLots;
    }
  }, [quantity, stock.lotsize]);

  const incrementLots = () => {
    setLots(lots + 1);
  };

  const decrementLots = () => {
    if (lots > 1) {
      setLots(lots - 1);
    }
  };

  const incrementQuantity = () => {
    // var lotsize = stock.lotsize ? stock.lotsize : 1;
    // var new_quantity = Number(quantity) + Number(lotsize);
    // setQuantity(new_quantity);
    if(stock.exchange != "OPTSTK"){
      setQuantity(Number(quantity)+1);
    }
  };

  const decrementQuantity = () => {
    if(stock.exchange != "OPTSTK"){
      if(quantity > 1){
        setQuantity(Number(quantity)-1);
      }
    }
  };

  const handleOrderTypeToggle = () => {
    setIsLimitOrder(!isLimitOrder);
    //alert("Limit Order: "+!isLimitOrder)
  };

  // Calculate the change percentage
  const _Bid = stock.buyPrice = stock.live.Bid!=undefined ? stock.live.Bid : stock.buyPrice;
  const _Ask = stock.sellPrice = stock.live.Ask!=undefined ? stock.live.Ask : stock.sellPrice;
  const _Open = stock.live.Open!=undefined ? stock.live.Open : stock.open;
  const _High = stock.live.High!=undefined ? stock.live.High : stock.high;
  const _Low = stock.live.Low!=undefined ? stock.live.Low : stock.low;
  const _Prev_Close = stock.live.Prev_Close!=undefined ? stock.live.Prev_Close : stock.close;
  const _LTP = stock.live.LTP!=undefined ? stock.live.LTP : stock.lastTradedPrice;

  const Change_per = ((_LTP - _Prev_Close) / _Prev_Close * 100).toFixed(2);
  const Change = (_LTP - _Prev_Close).toFixed(2);
  const changeColor = Change > 0 ? "green" : "red";

  const buyDepth = stock.live.depth?.buy || [];
  const sellDepth = stock.live.depth?.sell || [];

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('backdrop')) {
      onClose();
    }
  };

  const handleOrderTypeChange = (event, newOrderType) => {
    setOrderType(newOrderType);
  };

  const handleQuantityChange = (event) => {
      setQuantity(parseInt(event.target.value) || 0); // Update quantity state with the new input value
    // const value = event.target.value;
    // console.log("I am working");
    // if (!isNaN(value) && value >= 0) {
    //   setQuantity(value);
    // }
    // else{
    //   setQuantity('');
    // }
  };

  const createOrder_old = (orderType) => {
    const orderDetails = {
      stock,
      quantity,
      price: isLimitOrder === true ? price : (orderType === 'buy' ? _Ask : _Bid),
      orderType
    };
    console.log(orderDetails); // Replace this with your order creation logic
  };

  const createOrder = async (orderType) => {
    
    setDisable(true);

    if (userDetails.mainAmount <= 0) {
      alert("You don't have balance.");
      setDisable(false);

      var data = JSON.stringify({
        clientId: userDetails.clientId,
        parent_id: userDetails.parentId,
        parent_id: userDetails.parentId,
        stockId: stock.instrumentIdentifier,
        stockName: '"'+stock.dispname+'"',
        stockExpiry: '"'+stock.expiry+'"',
        orderTransactionType: 1,
        orderExecutionType: isLimitOrder ? 2 : 1,
        buy_sell: orderType,
        lot: lots,
        quantity: quantity,
        remark: '"You do not have balance."',
        orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
      });

      submitRejectionLog(data);

      return;
    }

    if (quantity < 1) {
      alert("Quantity should be at least 1.");
      setDisable(false);

      var data = JSON.stringify({
        clientId: userDetails.clientId,
        parent_id: userDetails.parentId,
        stockId: stock.instrumentIdentifier,
        stockName: '"'+stock.dispname+'"',
        stockExpiry: '"'+stock.expiry+'"',
        orderTransactionType: 1,
        orderExecutionType: isLimitOrder ? 2 : 1,
        buy_sell: orderType,
        lot: lots,
        quantity: quantity,
        remark: '"Quantity should be at least 1."',
        orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
      });

      submitRejectionLog(data);

      return;
    }

    if(stock.exchange === "OPTSTK"){
      if (typeof lots === 'number' && lots % 1 !== 0) {
        // console.log("The value is in points (decimal).");

        alert("Lots should not be in points.");
        setDisable(false);

        var data = JSON.stringify({
          clientId: userDetails.clientId,
          parent_id: userDetails.parentId,
          stockId: stock.instrumentIdentifier,
          stockName: '"'+stock.dispname+'"',
          stockExpiry: '"'+stock.expiry+'"',
          orderTransactionType: 1,
          orderExecutionType: isLimitOrder ? 2 : 1,
          buy_sell: orderType,
          lot: lots,
          quantity: quantity,
          remark: '"Lots should not be in points."',
          orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
        });

        submitRejectionLog(data);

        return;

      }
    }

    if (orderType === 2 && stock.exchange === "OPTSTK") {
      if (Number(userDetails.availableMargin) < Number(userDetails.OPTSTK_balance)) {
        alert(`You don't have sufficient margin to sell.\nMinimum ${userDetails.OPTSTK_balance} is required.`);
        setDisable(false);

        var data = JSON.stringify({
          clientId: userDetails.clientId,
          parent_id: userDetails.parentId,
          stockId: stock.instrumentIdentifier,
          stockName: '"'+stock.dispname+'"',
          stockExpiry: '"'+stock.expiry+'"',
          orderTransactionType: 1,
          orderExecutionType: isLimitOrder ? 2 : 1,
          buy_sell: orderType,
          lot: lots,
          quantity: quantity,
          remark: '"You don not have sufficient margin to sell."',
          orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
        });
  
        submitRejectionLog(data);

        return;
      }
    }

    // const nowTime = new Date();
    // const lastTradeTimeUnix = new Date(stock.lastTradeTime * 1000);
    // const timeDiffInSecond = Math.ceil((nowTime - lastTradeTimeUnix) / 1000);

    // if (stock.autoCutDate <= new Date().toISOString().split('T')[0]) {
    //   alert("You can't trade on autocut date.\nOpened position can be closed from portfolio.");
    //   var data = JSON.stringify({
    //     clientId: userDetails.clientId,
    //     parent_id: userDetails.parentId,
    //     stockId: stock.instrumentIdentifier,
    //     stockName: '"'+stock.dispname+'"',
    //     stockExpiry: '"'+stock.expiry+'"',
    //     orderTransactionType: 1,
    //     orderExecutionType: isLimitOrder ? 2 : 1,
    //     buy_sell: orderType,
    //     lot: lots,
    //     quantity: quantity,
    //     remark: '"You can not trade on autocut date.\nOpened position can be closed from portfolio."',
    //     orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
    //   });

    //   submitRejectionLog(data);
    //   return;
    // }

    if (stock.buyPrice <= 0 || stock.sellPrice <= 0) {
      alert("Price not updated, please refresh");
      setDisable(false);

      var data = JSON.stringify({
        clientId: userDetails.clientId,
        parent_id: userDetails.parentId,
        stockId: stock.instrumentIdentifier,
        stockName: '"'+stock.dispname+'"',
        stockExpiry: '"'+stock.expiry+'"',
        orderTransactionType: 1,
        orderExecutionType: isLimitOrder ? 2 : 1,
        buy_sell: orderType,
        lot: lots,
        quantity: quantity,
        remark: '"Price not updated, please refresh."',
        orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
      });

      submitRejectionLog(data);
      
      return;
    }

    if (userDetails.availableMargin <= 0) {
      alert("You do not have sufficient margin.");
      setDisable(false);

      var data = JSON.stringify({
        clientId: userDetails.clientId,
        parent_id: userDetails.parentId,
        stockId: stock.instrumentIdentifier,
        stockName: '"'+stock.dispname+'"',
        stockExpiry: '"'+stock.expiry+'"',
        orderTransactionType: 1,
        orderExecutionType: isLimitOrder ? 2 : 1,
        buy_sell: orderType,
        lot: lots,
        quantity: quantity,
        remark: '"You do not have sufficient margin."',
        orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
      });

      submitRejectionLog(data);
      return;
    }

    let orderAmount = 0;

    if (isLimitOrder) {

      if(price == _LTP){
          alert("Order price should not be equal to LTP.");
          setDisable(false);

          var data = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: stock.instrumentIdentifier,
            stockName: '"'+stock.dispname+'"',
            stockExpiry: '"'+stock.expiry+'"',
            orderTransactionType: 1,
            orderExecutionType: 2,
            buy_sell: orderType,
            lot: lots,
            quantity: quantity,
            remark: '"Order price should not be equal to LTP."',
            orderPrice: price
          });
    
          submitRejectionLog(data);

          return;
      }

      orderAmount = quantity * price;

      if(Number(price) < Number(stock.lower_circuit) || Number(price) > Number(stock.upper_circuit)) {
          alert("Order price is beyond daily price range.");
          
          setDisable(false);

          var data = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: stock.instrumentIdentifier,
            stockName: '"'+stock.dispname+'"',
            stockExpiry: '"'+stock.expiry+'"',
            orderTransactionType: 1,
            orderExecutionType: 2,
            buy_sell: orderType,
            lot: lots,
            quantity: quantity,
            remark: '"Order price is beyond daily price range"',
            orderPrice: price
          });

          submitRejectionLog(data);

          return;
      }

      if (orderType === 1) {
        if (price >= stock.buyPrice) {
          alert("Order price must be less than bid price.");
          setDisable(false);

          var data = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: stock.instrumentIdentifier,
            stockName: '"'+stock.dispname+'"',
            stockExpiry: '"'+stock.expiry+'"',
            orderTransactionType: 1,
            orderExecutionType: 2,
            buy_sell: orderType,
            lot: lots,
            quantity: quantity,
            remark: '"Order price must be less than bid price"',
            orderPrice: price
          });

          submitRejectionLog(data);

          return;
        }
      } else {
        if (price <= stock.sellPrice) {
          alert("Order price should be greater than ask price.");
          setDisable(false);

          var data = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: stock.instrumentIdentifier,
            stockName: '"'+stock.dispname+'"',
            stockExpiry: '"'+stock.expiry+'"',
            orderTransactionType: 1,
            orderExecutionType: 2,
            buy_sell: orderType,
            lot: lots,
            quantity: quantity,
            remark: '"Order price should be greater than ask price"',
            orderPrice: price
          });

          submitRejectionLog(data);

          return;
        }
      }
    }

    if (orderType === 2) 
    {
        if (stock.buyPrice <= 0) 
        {
            alert("Price not updated, please refresh");
            setDisable(false);

            var data = JSON.stringify({
              clientId: userDetails.clientId,
              parent_id: userDetails.parentId,
              stockId: stock.instrumentIdentifier,
              stockName: '"'+stock.dispname+'"',
              stockExpiry: '"'+stock.expiry+'"',
              orderTransactionType: 1,
              orderExecutionType: isLimitOrder ? 2 : 1,
              buy_sell: orderType,
              lot: lots,
              quantity: quantity,
              remark: '"Price not updated, please refresh."',
              orderPrice: stock.buyPrice
            });
  
            submitRejectionLog(data);

            return;
        }
        orderAmount = quantity * stock.buyPrice;
    } 
    else if (orderType === 1) {
      if (stock.sellPrice <= 0) {
          alert("Price not updated, please refresh");
          setDisable(false);

          var data = JSON.stringify({
            clientId: userDetails.clientId,
            parent_id: userDetails.parentId,
            stockId: stock.instrumentIdentifier,
            stockName: '"'+stock.dispname+'"',
            stockExpiry: '"'+stock.expiry+'"',
            orderTransactionType: 1,
            orderExecutionType: isLimitOrder ? 2 : 1,
            buy_sell: orderType,
            lot: lots,
            quantity: quantity,
            remark: '"Price not updated, please refresh."',
            orderPrice: stock.sellPrice
          });

          submitRejectionLog(data);

          return;
      }
      orderAmount = quantity * stock.sellPrice;
    }

    let requiredMargin = 0;

    if (stock.exchange === "FUTCOM") {
      requiredMargin = orderAmount / userDetails.FUTCOM_intraday;
    } else if (stock.exchange === "FUTSTK") {
      requiredMargin = orderAmount / userDetails.FUTSTK_intraday;
    } else if (stock.exchange === "OPTSTK") {
      requiredMargin = orderAmount / userDetails.OPTSTK_intraday;
    }

    if (orderType === 2 && stock.exchange === "OPTSTK") {
      requiredMargin = lots * userDetails.OPTSTK_balance;
    }

    if (requiredMargin > userDetails.availableMargin) {
      alert(`Can't place order.\nRequired margin: ${Number(requiredMargin).toFixed(2)}, current margin: ${Number(userDetails.availableMargin).toFixed(2)}`);
      setDisable(false);

      var data = JSON.stringify({
        clientId: userDetails.clientId,
        parent_id: userDetails.parentId,
        stockId: stock.instrumentIdentifier,
        stockName: '"'+stock.dispname+'"',
        stockExpiry: '"'+stock.expiry+'"',
        orderTransactionType: 1,
        orderExecutionType: isLimitOrder ? 2 : 1,
        buy_sell: orderType,
        lot: lots,
        quantity: quantity,
        remark: '"Can not place order. Required margin: '+Number(requiredMargin).toFixed(2)+', current margin: '+Number(userDetails.availableMargin).toFixed(2)+'"',
        orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
      });

      submitRejectionLog(data);

      return;
    }

    if (lots <= 0 || lots <= 0.0) {
      alert("Please enter lot quantity.");
      setDisable(false);

      var data = JSON.stringify({
        clientId: userDetails.clientId,
        parent_id: userDetails.parentId,
        stockId: stock.instrumentIdentifier,
        stockName: '"'+stock.dispname+'"',
        stockExpiry: '"'+stock.expiry+'"',
        orderTransactionType: 1,
        orderExecutionType: isLimitOrder ? 2 : 1,
        buy_sell: orderType,
        lot: lots,
        quantity: quantity,
        remark: '"Please enter lot quantity."',
        orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
      });

      submitRejectionLog(data);

      return;
    }

    stock.dispName = stock.dispname;

    try {
      const response = await fetch('https://8stockheight.live/mediator.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          stock,
          orderTypeBuySell: orderType,
          orderExecutionType: isLimitOrder ? 2 : 1,
          lots: lots,
          orderTransactionType: 1,
          market_type: `${stock.exchange}_intraday`,
          limitSlPrice: price,
          orderDevice: 1,
          user_id: userDetails.clientId,
          parent_id: userDetails.parentId,
          ip: process.env.REACT_APP_USER_IP
        }),
      });
      const data = await response.json();

      if (!data.success) {
        
        setDisable(false);
        
        var msg = data.message;
        
        if (data.message === "open position") {
          msg = "Please close position from portfolio.";
        } else if (data.message === "open pending position") {
          msg = "You have a pending limit order on this script.\nYou can cancel it from Trades."
        } else if (data.message.includes("Blocked")) {
          msg = "Trading not allowed on this script.";
        } else {
          msg = data.message;
          //alert(data.message);
        }


        showToast(3, msg, "Order Failed");
        
        var dataa = JSON.stringify({
          clientId: userDetails.clientId,
          parent_id: userDetails.parentId,
          stockId: stock.instrumentIdentifier,
          stockName: '"'+stock.dispname+'"',
          stockExpiry: '"'+stock.expiry+'"',
          orderTransactionType: 1,
          orderExecutionType: isLimitOrder ? 2 : 1,
          buy_sell: orderType,
          lot: lots,
          quantity: quantity,
          remark: '"'+msg+'"',
          orderPrice: isLimitOrder ? price : (orderType==1 ? stock.sellPrice : stock.buyPrice)
        });
  
        submitRejectionLog(dataa);
      } 
      else 
      {
        setLots(1);
        

        // if (isLimitOrder){
        //   showToast(1, b_s+" "+lots+" Lot(s) of "+stock.dispName+" @"+Number(data.orderPrice).toFixed(2));
        // }
        // else{
        //   showToast(2, b_s+" "+lots+" Lot(s) of "+stock.dispName+" @"+Number(data.orderPrice).toFixed(2));
        // }
        
        onClose();

        setDisable(false);

        var b_s = orderType == 1 ? 'Buy' : 'Sell';
        
        if (isLimitOrder) {
          var msg = b_s+" "+Number(lots).toFixed(2)+" Lot(s) of "+stock.dispName+" @"+Number(data.orderPrice).toFixed(2);
          swal({
            title: "Limit Order Successful",
            text: msg,
            icon: "success",
            buttons: {
              confirm: "OK"
            },
          }).then((value) => {
            if (value) {
              //window.location = `trades.php?tb=${getSelectedRadioValue()}`;
              // location.reload();
            }
          });
        } else {
          var msg = b_s+" "+Number(lots).toFixed(2)+" Lot(s) of "+stock.dispName+" @"+Number(data.orderPrice).toFixed(2);
          swal({
            title: "Order Successful",
            text: msg,
            icon: "success",
            buttons: {
              confirm: "OK"
            },
          }).then((value) => {
            if (value) {
              // window.location = "portfolio.php";
            }
          });
        }

        refreshTradesAndPortfolio('both');

      }
    } catch (error) {
      console.error("Error:", error);
      setDisable(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {isOpen && <div className="backdrop" onClick={handleBackdropClick} />}
      {isOpen && <animated.div style={{ styles }} className="bottom-sheet">
        <Box className="bottom-sheet-header">
          <Typography variant="h6" className='bottom-sheet-scriptName'>
            <p>
                <span style={{fontWeight:'600'}}>{stock.dispname}</span> <span className='stock-expiry'>{stock.expiry}</span>
            </p>
            <p className='bottom-sheet-exchange'>{stock.exchange}</p>
          </Typography>
        
          {/* <IconButton onClick={onClose} className='bottomSheet-close-btn'>
            <CloseIcon />
          </IconButton> */}
          <p style={{fontSize:'14px', fontWeight:'500'}}>
            <span style={{ position:' absolute', right: '20px', fontWeight: '600'}}>
              ₹{Number(_LTP).toFixed(2)}
              {Change > 0 ? (
                  <img src={upIcon} alt="Up" className="change-icon" />
              ) : (
                  <img src={downIcon} alt="Down" className="change-icon" />
              )}
            </span> <br/> 
            <span className={changeColor}>{Change}({Change_per}{'%'})</span>
          </p>
        </Box>
        <Box className="bottom-sheet-content">
          <Grid container spacing={2} className="stock-details">
            <Grid item xs={3} className='center'>
              <Typography>Open</Typography>
              <Typography className='ohlc'>{Number(_Open).toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={3} className='center'>
              <Typography>High</Typography>
              <Typography className='ohlc'>{Number(_High).toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={3} className='center'>
              <Typography>Low</Typography>
              <Typography className='ohlc'>{Number(_Low).toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={3} className='center'>
              <Typography>Close</Typography>
              <Typography className='ohlc'>{Number(_Prev_Close).toFixed(2)}</Typography>
            </Grid>
          </Grid>
          {stock.exchange!='OPTSTK' && <div className="stock-details dpr" style={{marginTop:'3px !important'}}>
            <p style={{textAlign:'center', fontWeight:'600', fontSize:'12px'}}>Day Price Range: { stock.lower_circuit } - { stock.upper_circuit }</p>
          </div>}
          {buyDepth.length > 0 && (
            <>
              <Typography variant="h6" className='market-depth-title' onClick={toggleDepth} style={{ cursor: 'pointer', display: 'flex', justifyContent:'space-between' }}>
                 {/* <span>Market Depth {isDepthExpanded ? '-' : '+'}</span> */}
                 <span style={{fontWeight:600}}>Market Depth</span>
                 <span style={{fontWeight:600}}>Balance: {Number(userDetails.availableMargin).toFixed(2)}</span>
              </Typography>
              {isDepthExpanded && (
                <div className='market-depth'>
                  <Box className="order-depth">
                    <TableContainer component={Paper} className='noborder'>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className='left-align padding5'>Qty</TableCell>
                            <TableCell className='right-align padding5'>Buy Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {buyDepth.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className='left-align padding5 noborder'>{row.quantity}</TableCell>
                              <TableCell className='green right-align padding5 noborder'>{Number(row.price).toFixed(2)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box className="order-depth">
                    <TableContainer component={Paper} className='noborder'>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className='left-align padding5'>Sell Price</TableCell>
                            <TableCell className='right-align padding5'>Qty</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sellDepth.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className='red left-align padding5 noborder'>{Number(row.price).toFixed(2)}</TableCell>
                              <TableCell className='right-align padding5 noborder'>{row.quantity}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              )}
            </>
          )}

          <Box className="lot-quantity-section">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} className='center'>
                <Typography>Lots</Typography>
                <div style={{textAlign:'center'}}>
                  <Button className='plus-minus_btn' onClick={decrementLots}>-</Button>
                  <input value={Number(lots).toFixed(2)} variant="outlined" size="small" disabled  className='input-text-field'/>
                  <Button className='plus-minus_btn' onClick={incrementLots}>+</Button>
                </div>
              </Grid>
              <Grid item xs={6} className='center'>
                <Typography>Quantity</Typography>
                <div style={{textAlign:'center'}}>
                  <Button className='plus-minus_btn' onClick={decrementQuantity}>-</Button>
                  {stock.exchange === "OPTSTK" && <input value={Number(quantity)} variant="outlined" size="small" min="1" step="0.1" disabled onChange={handleQuantityChange} className='input-text-field'/>}
                  {stock.exchange != "OPTSTK" && <input value={Number(quantity)} variant="outlined" size="small" min="1" step="0.1" inputProps={{ readOnly: false }} onChange={handleQuantityChange} className='input-text-field'/>}
                  <Button className='plus-minus_btn' onClick={incrementQuantity}>+</Button>
                </div>
              </Grid>
            </Grid>
          </Box>
            
            {stock.exchange!='OPTSTK' && <Box className="order-type-section" style={{display:'flex'}}>
                
                <div style={{ textAlign:'center', margin:'3px auto'}}>
                    <div className="toggle-switch" style={{width:'139px'}}>
                      <span className={!isLimitOrder ? 'active' : ''} onClick={handleOrderTypeToggle}>Market</span>
                      <span className={isLimitOrder ? 'active' : ''} onClick={handleOrderTypeToggle}>Limit</span>
                    </div>
                </div>
              
              {isLimitOrder && (
                <div style={{ textAlign:'center'}}>
                  {/* <Button className='plus-minus_btn' onClick={decrementQuantity}>-</Button> */}
                      <input type="number"
                        label="Price"
                        variant="outlined"
                        size="small"
                        value={price}
                        placeholder='Price'
                        className='input-text-field limit-price-box'
                        onChange={(e) => setPrice(e.target.value)}
                      />
                  {/* <Button className='plus-minus_btn' onClick={incrementQuantity}>+</Button> */}
                </div>
                
                    
              )}
              
            </Box>}
          
          
          
            <Box className="bottom-actions">
            
                {/* {(userDetails.OPTSTK_sellAllow === "1" && stock.exchange == "OPTSTK") || (stock.exchange != "OPTSTK") && <Button variant="contained" color="secondary" disabled={disableBtn} className='button-sell' onClick={() => createOrder(2)} >
                  Sell {userDetails.OPTSTK_sellAllow} {stock.exchange}
                </Button>} */}
                {(userDetails.OPTSTK_sellAllow === "1" && stock.exchange === "OPTSTK") || stock.exchange !== "OPTSTK" ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={disableBtn}
                    className='button-sell'
                    onClick={() => createOrder(2)}
                  >
                    Sell
                  </Button>
                ) : null}
                  
                <Button variant="contained" color="primary" disabled={disableBtn} className='button-buy' onClick={() => createOrder(1)} >
                  Buy
                </Button>
            </Box>
            {buyDepth.length > 0 && <Box className="bottom-actions" style={{ marginTop:'3px', fontSize: '11px', color: 'gray' }}>
                  {/* <p>
                    Intraday Margin: 
											{ stock.exchange=='OPTSTK' ? userDetails.OPTSTK_balance * lots : ((quantity*buyDepth[0]['price'])/stock.intraday).toFixed(2) }
                  </p> */}
                  {(userDetails.OPTSTK_sellAllow === "1" && stock.exchange === "OPTSTK") || stock.exchange !== "OPTSTK" ? (
                    <p>
                    Intraday Margin: 
											{ stock.exchange=='OPTSTK' ? userDetails.OPTSTK_balance * lots : ((quantity*buyDepth[0]['price'])/stock.intraday).toFixed(2) }
                  </p>
                ) : null}
                  <p> Intraday Margin: { ((quantity*sellDepth[0]['price'])/stock.intraday).toFixed(2) }  </p>
            </Box> }
        </Box>
      </animated.div>}
    </>
  );
};

export default BottomSheet;
