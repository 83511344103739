// import React, { useEffect, useState, useContext } from 'react';
// import PositionCard from './../components/PositionCard';
// import { WebSocketContext } from "../WebSocketContext";
// import config from '../config'; // Assuming you have a config file with API_BASE_URL
// import '../Portfolio.css'; // Assuming you will style the portfolio page

// const Portfolio = ({ user_id }) => {
//   const [positions, setPositions] = useState([]);
//   const [userDetails, setUserDetails] = useState([]);
//   const { stocks, lastUpdateTime } = useContext(WebSocketContext);

//   useEffect(() => {
//     const fetchPositions = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}portfolio`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ user_id }),
//         });
//         const data = await response.json();
//         // Assuming data is an array of positions
//         setPositions(data);
//       } catch (error) {
//         console.error('Error fetching portfolio positions:', error);
//       }
//     };

//     const fetchUserDetails = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}getUserDetails`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d`
//           },
//           body: JSON.stringify({ user_id }),
//         });
//         const data = await response.json();
//         // Assuming data is an array of positions
//         setUserDetails(data);
//       } catch (error) {
//         console.error('Error fetching user details:', error);
//       }
//     };

//     fetchUserDetails();
//     fetchPositions();
//   }, [user_id]);

//   return (
//     <div className="portfolio-page">
//       {/* <div className="position-list"> */}
//         {positions.map(position => (
//           <PositionCard stock={stocks[position.tradingsymbol]} userDetails={userDetails} key={position.orderId} position={position} />
//         ))}
//       {/* </div> */}
//     </div>
//   );
// }

// export default Portfolio;


// import React, { useEffect, useState, useContext } from 'react';
// import PositionCard from './../components/PositionCard';
// import { WebSocketContext } from "../WebSocketContext";
// import config from '../config'; // Assuming you have a config file with API_BASE_URL
// import '../Portfolio.css'; // Assuming you will style the portfolio page

// const Portfolio = ({ user_id }) => {
//   const [positions, setPositions] = useState([]);
//   const [userDetails, setUserDetails] = useState({});
//   const { stocks, lastUpdateTime } = useContext(WebSocketContext);

//   useEffect(() => {
//     const fetchPositions = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}portfolio`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ user_id }),
//         });
//         const data = await response.json();
//         setPositions(data); // Assuming data is an array of positions
//       } catch (error) {
//         console.error('Error fetching portfolio positions:', error);
//       }
//     };

//     const fetchUserDetails = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}getUserDetails`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
//           },
//           body: JSON.stringify({ user_id }),
//         });
//         const data = await response.json();
//         setUserDetails(data); // Assuming data is an object of user details
//       } catch (error) {
//         console.error('Error fetching user details:', error);
//       }
//     };

//     fetchUserDetails();
//     fetchPositions();
//   }, [user_id]);

//   return (
//     <div className="portfolio-page">
//       {positions.length > 0 && Object.keys(userDetails).length > 0 ? (
//         positions.map(position => {
//           const stock = stocks[position.tradingsymbol] || {}; // Provide a default value if undefined
//           return (
//             <PositionCard 
//               key={position.orderId} 
//               stock={stock} 
//               userDetails={userDetails} 
//               position={position} 
//             />
//           );
//         })
//       ) : (
//         <p>Loading positions...</p>
//       )}
//     </div>
//   );
// }

// export default Portfolio;


// import React, { useEffect, useState, useContext } from 'react';
// import PositionCard from './../components/PositionCard';
// import { WebSocketContext } from "../WebSocketContext";
// import config from '../config'; // Assuming you have a config file with API_BASE_URL
// import '../Portfolio.css'; // Assuming you will style the portfolio page

// const Portfolio = ({ user_id }) => {
//   const [positions, setPositions] = useState([]);
//   const [userDetails, setUserDetails] = useState({});
//   const { stocks, lastUpdateTime } = useContext(WebSocketContext);

//   useEffect(() => {
//     const fetchPositions = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}portfolio`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({ user_id }),
//         });
//         const data = await response.json();
//         setPositions(data); // Assuming data is an array of positions
//       } catch (error) {
//         console.error('Error fetching portfolio positions:', error);
//       }
//     };

//     const fetchUserDetails = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}getUserDetails`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
//           },
//           body: JSON.stringify({ user_id }),
//         });
//         const data = await response.json();
//         setUserDetails(data); // Assuming data is an object of user details
//       } catch (error) {
//         console.error('Error fetching user details:', error);
//       }
//     };

//     fetchUserDetails();
//     fetchPositions();
//   }, [user_id]);

//   // Calculate total PnL
//   const calculateTotalPnL = () => {
//     return positions.reduce((total, position) => {
//       const stock = stocks[position.tradingsymbol] || {};
//       const pnl = calculatePnL(position, stock);
//       return total + pnl;
//     }, 0);
//   };

//   // Function to calculate PnL for each position
//   const calculatePnL = (position, stock) => {
//     if (position.buy_sell === "1") {
//       if (stock.Bid === 0) return 0;
//       else return (stock.Bid * position.orderQuantity) - (position.orderPrice * position.orderQuantity);
//     } else {
//       if (stock.Ask === 0) return 0;
//       else return (position.orderPrice * position.orderQuantity) - (stock.Ask * position.orderQuantity);
//     }
//   };

//   return (
//     <div className="portfolio-page">
//       <div className="total-pnl">
//         <h2>Total PnL: {calculateTotalPnL()}</h2>
//       </div>
//       {positions.length > 0 && Object.keys(userDetails).length > 0 ? (
//         positions.map(position => {
//           const stock = stocks[position.tradingsymbol] || {};
//           return (
//             <PositionCard 
//               key={position.orderId} 
//               stock={stock} 
//               userDetails={userDetails} 
//               position={position} 
//             />
//           );
//         })
//       ) : (
//         <p>Loading positions...</p>
//       )}
//     </div>
//   );
// }

// export default Portfolio;



import React, { useEffect, useState, useContext } from 'react';
import PositionCard from './../components/PositionCard';
import BottomSheetPortfolio from './../components/BottomSheetPortfolio';
import { WebSocketContext } from "../WebSocketContext";
import config from '../config'; // Assuming you have a config file with API_BASE_URL
import '../Portfolio.css'; // Assuming you will style the portfolio page
import { Refresh } from '@mui/icons-material';

const Portfolio = () => {
  //const [positions, setPositions] = useState([]);
  //const [userDetails, setUserDetails] = useState({});
  //const { stocks, lastUpdateTime } = useContext(WebSocketContext);
  const { performance, positions = [], userScripts=[], stocks, userDetails, refreshTradesAndPortfolio } = useContext(WebSocketContext);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  let oldRates = [];
  let userScriptsTemp = [];

  const toggleDepth = () => {
    setIsDetailsExpanded(!isDetailsExpanded);
  };

  const handleCardClick = (position) => {
    setSelectedStock(position);
    // if(stock.exchange=="OPTSTK"){
    //   stock.intraday = userDetails.OPTSTK_intraday;
    // } else if(stock.exchange=="FUTSTK"){
    //   stock.intraday = userDetails.FUTSTK_intraday;
    // } else if(stock.exchange=="FUTCOM"){
    //   stock.intraday = userDetails.FUTCOM_intraday;
    // }
    setShowBottomSheet(true);
  };

  const handleCloseSheet = () => {
    setShowBottomSheet(false);
    setSelectedStock(null);
    // alert("closed"+showBottomSheet);
  };

  // Function to get the latest stock data for the selected stock
  const getLatestStockData = (stock) => {
    return stocks[stock.instrument_token] || {};
  };

  

  // Effect to update the selected stock data in the bottom sheet whenever stocks context updates
  useEffect(() => {
    
    if (selectedStock) {
      const latestStockData = getLatestStockData(selectedStock);
      setSelectedStock((prevStock) => ({
        ...prevStock,
        live: latestStockData,
      }));
    }
  }, [stocks, selectedStock]);

  // Calculate total PnL
  const calculateTotalPnL = () => {
    return positions.reduce((total, position) => {
      //console.log("calc pnls : ",position);
      const stock = stocks[position.instrument_token] || {};
      const pnl = Number(calculatePnL(position, stock));
      var t =Number(Number(total) + pnl - Number(position.brokerage)).toFixed(2); 
      
      return t;
    }, 0);
  };

  const calculateBookedPnL = () => {

    if (!Array.isArray(performance)) {
      performance = [performance]; // Ensure it's an array
    }

    return performance.reduce((total, position) => {
      //const stock = stocks[position.instrument_token] || {};
      //console.log("calc pnl", position);
      const pnl = Number(position.closing_order.pnl);
      var t =Number(Number(total) + pnl - Number(position.brokerage) - Number(position.closing_order.brokerage)).toFixed(2); 
      
      return t;
    }, 0);
  };

  // const calculateTotalPnl = () => {
  //   return Number( Number(calculatePnL()) + Number(calculateBookedPnL()) ).toFixed(2);
  // };

  // Function to calculate PnL for each position
  const calculatePnL = (position, stock) => {
    if (position.buy_sell === "1") {
      if(stock.Bid==undefined) stock.Bid = position.buyPrice;
      if (stock.Bid === undefined){ 
        //console.log(stock.stockName, stock.Bid);
        return 0;
      }
      else
      { 
        //console.log(stock.stockName, stock.Bid);
        return (stock.Bid * position.orderQuantity) - (position.orderPrice * position.orderQuantity);
      }
    } 
    else 
    {
      if(stock.Ask==undefined) stock.Ask = position.sellPrice;
      if (stock.Ask === undefined){ 
        //console.log(stock.stockName, stock.Ask);
        return 0;
      }
      else
      {
          //console.log(stock.stockName, stock.Ask);
         return (position.orderPrice * position.orderQuantity) - (stock.Ask * position.orderQuantity);
      }
    }
  };

  const handleRefresh = () => {
    refreshTradesAndPortfolio("both");
  };

  return (
    <div className="portfolio-page">
      {/* <div className="total-pnl">
        <h2>Total PnL: {calculateTotalPnL()}</h2>
      </div> */}
      <button className="refresh-button" onClick={handleRefresh}><Refresh /></button>
      {/* <div className="pnl-display">
        <span className="pnl-title">Total P&L </span>
        <span className={`pnl-value ${calculateTotalPnL() < 0 ? 'negative' : 'positive'}`}>
        ₹{calculateTotalPnL()}
        </span>
      </div> */}

      <div  className="pnl-display" onClick={toggleDepth} style={{display:'flex', padding:'0px'}}>
        <div className="pnl-display" style={{display:'block', margin:'0px'}}>
          <span className="pnl-title">Current P&L </span>
          <p className={`pnl-value ${calculateTotalPnL() < 0 ? 'negative' : 'positive'}`}>
          ₹{calculateTotalPnL()}
          </p>
        </div>
        <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'center'}}>
          <span className="pnl-title">Booked P&L </span>
          <p className={`pnl-value ${calculateBookedPnL() < 0 ? 'negative' : 'positive'}`}>
          ₹{calculateBookedPnL()}
          </p>
        </div>
        <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'right'}}>
          <span className="pnl-title">Net P&L </span>
          <p className={`pnl-value ${ (Number(calculateBookedPnL())+Number(calculateTotalPnL())) < 0 ? 'negative' : 'positive'}`}>
          ₹{ Number(Number(calculateBookedPnL()) +Number(calculateTotalPnL())).toFixed(2)}
          </p>
        </div>
        
      </div>

      {positions.length > 0 && Object.keys(userDetails).length > 0 ? (
        positions.map(position => {
          const stock = stocks[position.instrument_token] || {};
          //console.log(position);
          return (
            <PositionCard 
              key={position.orderId} 
              stock={stock} 
              userDetails={userDetails} 
              position={position} 
              onCardClick={handleCardClick}
              isMLMClient = {false}
            />
          );
        })
      ) : (
        <p>Loading positions...</p>
      )}
      {selectedStock && <BottomSheetPortfolio isOpen={showBottomSheet} onClose={handleCloseSheet} userDetails={userDetails} position={selectedStock} />}
    </div>
  );
}

export default Portfolio;

