import React, { useContext, useState, useEffect } from 'react';
import MLMTradeCard from './../components/MLMTradeCard';
import { WebSocketContext } from "../WebSocketContext";
import './../Trades.css'; // Assuming you have a CSS file for styles
import { Refresh } from '@mui/icons-material';
import config from '../config';

const MLMDashboard = () => {
  const { stocks, userDetails, refreshTradesAndPortfolio } = useContext(WebSocketContext);
  const [filter, setFilter] = useState('executed');
  const [trades, setTrades] = useState([]);
  const user_id = Number(localStorage.getItem('userId'));
  const [isRefreshing, setRefreshing] = useState(false);
  const user_type = "brokerMLM";
  const parent_id = Number(localStorage.getItem('parentId'));

  function getWeekNumber(date = new Date()) {
        // Copy the date object to avoid modifying the original
        const currentDate = new Date(date.getTime());
        
        // Set the day of the week to Thursday to account for ISO-8601 week standard
        currentDate.setUTCHours(0, 0, 0, 0);
        currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));
        
        // Get the first day of the year
        const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
        
        // Calculate the week number
        const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);
        
        return weekNumber;
    }

    const week = getWeekNumber();


  // Function to fetch trades from API
  const fetchTrades = async (user_id) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}getDownOrdersMLM`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id, user_type, parent_id, week }),
      });
      const data = await response.json();
      
      // Sort the data by orderId in descending order
      const sortedData = data.sort((a, b) => b.orderId - a.orderId);
      
      setTrades(sortedData); // Assuming data is an array of trades
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching trades:', error);
      setRefreshing(false);
    }
  };

  useEffect(() => {

    fetchTrades(user_id);

  }, []);

  const handleRefresh = () => {
    // refreshTradesAndPortfolio("both");
    fetchTrades(user_id);
  };

  const filteredTrades = trades.filter(trade => {
    if (filter === 'executed') return trade.orderStatus == "2" || trade.orderStatus == "3";
    if (filter === 'pending') return trade.orderStatus == "1";
    if (filter === 'cancelled') return trade.orderStatus == "4";
    return true;
  });

  return (
    <div className="trades-page">
      <div className="toggle-switch" style={{width:'255px', margin:'0px auto 10px'}}>
        <button 
          className={filter === 'pending' ? 'active' : ''} 
          onClick={() => setFilter('pending')}
        >
          Pending
        </button>
        <button 
          className={filter === 'executed' ? 'active' : ''} 
          onClick={() => setFilter('executed')}
        >
          Executed
        </button>
        <button 
          className={filter === 'cancelled' ? 'active' : ''} 
          onClick={() => setFilter('cancelled')}
        >
          Cancelled
        </button>
      </div>
      <button className="refresh-button" onClick={handleRefresh}><Refresh /></button>
      {/* Render trades using stocks from WebSocketContext */}
      {filteredTrades.map(trade => (
        <MLMTradeCard 
          key={trade.orderId} 
        //   refreshTradesAndPortfolio={refreshTradesAndPortfolio}
          stock={stocks[trade.symbol]} // Access real-time stock data from WebSocketContext
          trade={trade} 
        />
      ))}
    </div>
  );
}

export default MLMDashboard;
