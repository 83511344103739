import React, { useEffect, useState, useContext } from 'react';
import PositionCard from '../components/PositionCard';
import BottomSheetPortfolio from '../components/BottomSheetPortfolio';
import { WebSocketContext } from "../WebSocketContext";
import config from '../config'; // Assuming you have a config file with API_BASE_URL
import '../Performance.css'; // Assuming you will style the portfolio page
import PerformanceCard from '../components/PerformanceCard';
import { Refresh } from '@mui/icons-material';

const MLMPerformance = () => {

  const { userDetails, refreshTradesAndPortfolio, refreshPerformance } = useContext(WebSocketContext);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const [performance, setPerformance] = useState([]);

  const user_id = Number(localStorage.getItem('userId'));
  const parent_id = Number(localStorage.getItem('parentId'));
  const [isRefreshing, setRefreshing] = useState(false);
  const user_type = "brokerMLM";

  let grossPnl = 0;
  let brokerage = 0;

  //console.log("Profile userDetails",userDetails);

  // Function to fetch portfolio from API
  const fetchPerformance = async (user_id) => {
    
    try {
        // console.log("Hello");
        // console.log(userDetails);
        // console.log("Hi: "+parent_id);
      const response = await fetch(`${config.API_BASE_URL}performanceFromMondayMLM`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id, user_type, parent_id }),
      });
      const data = await response.json();
      // /console.log("Data Received : "+data[0]['orderId']);

    //   const instrumentTokens = data.map(script => script.instrument_token);
    //   console.log(instrumentTokens);
      //setStockids(instrumentTokens);
      //wsServiceRef.current.mergerIDs(instrumentTokens);
    //   mergerIDsEx(instrumentTokens);
      //reconnectWebSocket();

      
    //   const sortedData = data.sort((a, b) => b.orderId - a.orderId);

      // Parse the input JSON response
      const orders = data;//JSON.parse(data);

      // Process orders to calculate pnl and brokers
      const processedOrders = orders.map(order => {
          let currentPnl = 0;

          if (order.buy_sell === 1) {
              if (order.buyPrice === 0) {
                  currentPnl = 0;
              } else {
                  currentPnl = (order.buyPrice * order.orderQuantity) - 
                               (order.orderPrice * order.orderQuantity);
              }
          } else {
              if (order.sellPrice === 0) {
                  currentPnl = 0;
              } else {
                  currentPnl = (order.orderPrice * order.orderQuantity) - 
                               (order.sellPrice * order.orderQuantity);
              }
          }

          // Add pnl and pnl_for_total
          order.pnl = currentPnl;
          order.pnl_for_total = currentPnl - order.brokerage;

          // Decode broker_brokerage and extract brokers
        //   const brokerBrokerage = JSON.parse(order.broker_brokerage);
        //   const brokers = Object.keys(brokerBrokerage);
        //   order.brokers = brokers.join(",");

          return order;
      });

      // Sort orders in descending order of orderId
      const sortedOrders = processedOrders.sort((a, b) => b.orderId - a.orderId);

      setPerformance(sortedOrders);

    //   setPortfolio(sortedData); // Assuming data is an array of portfolio positions
      setRefreshing(false);
    } catch (error) {
      console.error('Error fetching portfolio:', error);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchPerformance(user_id);
  }, []);

  const toggleDepth = () => {
      setIsDetailsExpanded(!isDetailsExpanded);
    };

  //console.log("Performance rendered");
  
  // Calculate total PnL
  const calculateTotalPnL = () => {

    if (!Array.isArray(performance)) {
      performance = [performance]; // Ensure it's an array
    }

    // grossPnl = performance.reduce((total, position) => {
    //   const pnl = Number(position.closing_order.pnl);
    //   var t =Number(Number(total) + pnl).toFixed(2); 
    //   return t;
    // }, 0);

    brokerage = performance.reduce((total, position) => {
      //const stock = stocks[position.instrument_token] || {};
      //console.log("calc pnl", position);
      var open_brokerage = JSON.parse(position.broker_brokerage);
      var open_bro = open_brokerage[user_id];

      var close_brokerage = JSON.parse(position.closing_order.broker_brokerage);
      var close_bro = close_brokerage[user_id];

      var t =Number(Number(total) + Number(open_bro) + Number(close_bro)).toFixed(2); 
      
      return t;
    }, 0);

    return performance.reduce((total, position) => {
      //const stock = stocks[position.instrument_token] || {};
    //   console.log("calc pnl", position);
      const pnl = Number(position.closing_order.pnl);
      var t =Number(Number(total) + pnl - Number(position.brokerage) - Number(position.closing_order.brokerage)).toFixed(2); 
      
      return t;
    }, 0);
  };

  const handleRefresh = () => {
    refreshTradesAndPortfolio("both");
  };

  return (
    <div className="portfolio-page">
      <button className="refresh-button" onClick={handleRefresh}><Refresh /></button>
      <span style={{display:'none'}}>{calculateTotalPnL()}</span>

      <div  className="pnl-display" onClick={toggleDepth} style={{display:'flex', padding:'0px'}}>
        {/* <div className="pnl-display" style={{display:'block', margin:'0px'}}>
          <span className="pnl-title">Gross P&L </span>
          <p className={`pnl-value ${grossPnl < 0 ? 'negative' : 'positive'}`}>
          ₹{Number(grossPnl).toFixed(2)}
          </p>
        </div> */}
        {/* <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'center'}}> */}
        <div className="pnl-display" style={{display:'block', margin:'0px'}}>
          <span className="pnl-title">Brokerage </span>
          <p className={`pnl-value ${brokerage < 0 ? 'negative' : 'positive'}`}>
          ₹{Number(brokerage).toFixed(2)}
          </p>
        </div>
        <div className="pnl-display" style={{display:'block', margin:'0px', textAlign:'right'}}>
          <span className="pnl-title">Net P&L </span>
          <p className={`pnl-value ${ (calculateTotalPnL()) < 0 ? 'negative' : 'positive'}`}>
          ₹{ calculateTotalPnL()}
          </p>
        </div>
        
      </div>

      {/* <div  className="pnl-display" onClick={toggleDepth} style={{display:'block'}}>
        <div className="pnl-display" style={{display:'flex', padding:'0px', margin:'0px'}}>
          <span className="pnl-title">Total P&L </span>
          <span className={`pnl-value ${calculateTotalPnL() < 0 ? 'negative' : 'positive'}`}>
            ₹{calculateTotalPnL()}
          </span>
        </div>
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Gross P&L </span>
          <span>₹{Number(grossPnl).toFixed(2)}</span>
        </div>}
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Brokerage </span>
          <span>₹{Number(brokerage).toFixed(2)}</span>
        </div>}
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Available Margin </span>
          <span>₹{Number(userDetails.availableMargin ? userDetails.availableMargin : 0).toFixed(2)}</span>
        </div>}
        {isDetailsExpanded && <div className="pnl-display" style={{display:'flex', padding:'0px', marginBottom:'0px', marginTop:'10px'}}>
          <span className="pnl-title">Main Balance </span>
          <span>₹{Number(userDetails.mainAmount ? userDetails.mainAmount : 0).toFixed(2)}</span>
        </div>}
      </div> */}
      {performance.length > 0 && Object.keys(performance).length > 0 ? (
        performance.map(position => {
          return (
            <PerformanceCard 
              key={position.orderId} 
              performance={position}
            />
          );
        })
      ) : (
        <p>Loading performance...</p>
      )}
    </div>
  );
}

export default MLMPerformance;

