import React, { useState, useContext, useEffect} from 'react';
import { AppBar, Toolbar, Typography, IconButton, MenuList, Drawer, Link, List, ListItem, ListItemText,Box, Avatar } from '@mui/material';
import { ArrowBack, Menu } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import Profile from './../pages/Profile';
import { WebSocketContext } from "../WebSocketContext";
import config from '../config';
//import { Box, Avatar } from '@mui/material';
//import { Link } from 'react-router-dom';
import './../Profile.css';

import './../index.css';
// import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { getToken, storeToken, removeToken } from '../utils/auth'; // Adjust path as needed 974767

function TitleBar() {

  const { userDetails, isRefreshing } = useContext(WebSocketContext);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = Number(localStorage.getItem('userId'));
  const user_id = Number(localStorage.getItem('userId'));
  const parent_id = Number(localStorage.getItem('parentId'));
  const user_type = "brokerMLM";
  const clientName = userDetails.clientName;
  // const isMLMClient = userDetails?.isMLMClient ?? false;

  const isMLMClient = userDetails?.isMLMClient === "1";

  //  console.log(userDetails);


  const whatsappNumber = userDetails.supportContact; // Replace with your support number
  const whatsappMessage = 'Hello, I need support'; // Replace with your message
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;

  const referralLink = `https://app.papertrade.co.in/register?ud=${userId}`;
  const message = encodeURIComponent(
    `Hi! Join using my referral link: ${referralLink}`
  );

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };

  const [marqueeText, setMarqueeText] = useState(null);

    const getTodayScrollText = async (wsService) => {
        try 
        {  
          const response = await fetch(`${config.API_BASE_URL}getDefaultScrollText`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer 7c85cfc0ad2328331d4784ffedb97565154cd2016ed0b2b6843a53e7f2a2343d` // Ensure this is stored securely
            },
            body: JSON.stringify({ }),
          });
          
          const data = await response.json();
          
          setMarqueeText(data.text); 

        } catch (error) {
          console.error('Error fetching marquee text:', error);
        }
    };

    useEffect(() => {
      getTodayScrollText();
      }, []);


  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    //alert("called");
    setDrawerOpen(open);
  };

  const handleNavigation = (path) => () => {
      // navigate(path);
      // setDrawerOpen(false);
      setIsMLMMenuOpen(false);
      
      if (path.startsWith('http')) {
        window.open(path, '_blank');
      } else {
        navigate(path);
        location.pathname = path;
      }
      setDrawerOpen(false);
  };

  const doLogin =  () => {
    //removeToken();
    localStorage.removeItem('authToken');
    navigate('/login');
    setDrawerOpen(false);
  };

  const showBackButton = location.pathname === '/profile' || location.pathname === '/addScript';
  //alert(location.pathname === '/trades');

  const [isMLMMenuOpen, setIsMLMMenuOpen] = useState(false);

  const toggleMLMMenu = () => {
    setIsMLMMenuOpen(!isMLMMenuOpen);
  };

  return (
    <>
      <AppBar position="fixed" color="primary" style={{ backgroundColor: '#319197', boxShadow:'0px 2px 5px -2px rgba(0,0,0,0)', color:'white' }}>
        <Toolbar>
          {showBackButton && (
            <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
              <ArrowBack />
            </IconButton>
          )}
          {true && showBackButton==false && (
            <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)}>
              <Menu />
            </IconButton>
          )}
          <Typography variant="h6" style={{fontSize:'18px', fontFamily:'Lato', fontWeight:600}}>
            {location.pathname === '/' && 'Watchlist'}
            {location.pathname === '/trades' && 'Trades'}
            {location.pathname === '/portfolio' && 'Portfolio'}
            {location.pathname === '/profile' && 'Profile'}
            {location.pathname === '/performance' && 'Performance'}
            {location.pathname === '/blocked-scripts' && 'Blocked Scripts'}
            {location.pathname === '/change-password' && 'Change Password'}
            {location.pathname === '/addScript' && 'Add Script'}
            {location.pathname === '/rejection-logs' && 'Rejection Logs'}
            {location.pathname === '/mlmdashboard' && 'MLM Trades'}
            {location.pathname === '/mlmportfolio' && 'MLM Portfolio'}
            {location.pathname === '/mlmperformance' && 'MLM Performance'}
          </Typography>
        </Toolbar>
        <marquee style={{fontSize:'12px', fontFamily: 'Lato',marginTop:'-5px', marginBottom:'5px', fontWeight:'bold'}}>
          {marqueeText}
        {/* NSEOPT FINNIFTY EXPIRY & ROLLOVER ALERT 20AUG2024. PLEASE SQUARE-OFF OR ROLLOVER POSITION. FOR MORE INFORMATION PLEASE CONTACT SUPPORT. */}
        </marquee>

        {isRefreshing && <div style={{position: 'absolute', width: '100%', top: '10px'}}>
          <div style={{fontSize: '11px', color: '#062c2f', background: 'white', padding: '0px 7px', borderRadius: '11px', margin: '0px auto', width: '81px'}}>
            Refreshing....
          </div>
        </div>}

        {/* <div class="video-container">
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/TD0A7fHAxKw?si=HHPcqSBYA50Pqldz"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
            ></iframe>
        </div> */}


      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} style={{backgroundColor:'#f3f3f3 !important'}}>
          {/* <Profile userDetails={userDetails} setDrawerOpen={setDrawerOpen} /> */}
          <div className="container">
            <div className="header">

                {userDetails && <Box
                  sx={{
                    border: '1px solid #E0E0E0',
                    borderRadius: '12px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#f9f9f9',
                    maxWidth: '300px',
                    margin: 'auto',
                    marginBottom: '10px',
                  }}
                >
                  <Avatar
                    sx={{
                      width: 60,
                      height: 60,
                      bgcolor: '#c6dffe',
                      color: '#3e6089',
                      fontSize: '24px',
                      marginBottom: '10px',
                    }}
                  >
                    {/* {userDetails && clientName.split(" ").map((n) => n[0]).join("")} */}
                    {/* {clientName.split(" ").map((n) => n[0]).join("").toLowerCase()} */}
                  </Avatar>
                  <Typography variant="h6" sx={{ fontWeight: 500, color:'#000000' }}>
                    {userDetails.clientName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#757575' }}>
                    ID: {userDetails.clientId}
                  </Typography>
              </Box>}

              <div style={{display:'flex', alignContent:"space-between"}}>
                  <div className='left-content'>
                    <p className="margin-title">Avail. Margin:</p>
                    <p className="margin-text">{Number(userDetails.availableMargin).toFixed(2)}</p>
                  </div>
                  <div className='right-content'>
                    <p className="margin-title">Avail. Balance:</p>
                    <p className="margin-text">{Number(userDetails.mainAmount).toFixed(2)}</p>
                  </div>
              </div>
              
              
              {/* <div className="button-group">
                <button className="button deposit">Deposit</button>
                <button className="button withdraw">Withdraw</button>
              </div> */}
            </div>
            <ul className="list">
              <li className="list-item">
                <Link onClick={handleNavigation('/blocked-scripts')} className="list-item-link">
                  <span className="list-item-icon">🚫</span>
                  <span className="list-item-text">Blocked Scripts</span>
                </Link>
              </li><li className="list-item">
                <Link onClick={handleNavigation('/rejection-logs')} className="list-item-link">
                  <span className="list-item-icon">📄</span>
                  <span className="list-item-text">Rejection Logs</span>
                </Link>
              </li>
              <li className="list-item">
                <Link onClick={handleNavigation(`https://bluerock.live/ledger_app_wo_login.php?ud=${userId}`)} className="list-item-link">
                  <span className="list-item-icon">📒</span>
                  <span className="list-item-text">Ledger</span>
                </Link>
              </li>
  
              {/* MLM Menu */}
              {isMLMClient && (
                    <>
                      <li className="list-item" onClick={toggleMLMMenu} style={{ cursor: 'pointer' }}>
                        <span className="list-item-icon">{isMLMMenuOpen ? '▼' : '▶'}</span>
                        <span className="list-item-text mlm-menu-header">MLM</span>
                      </li>
                      {isMLMMenuOpen && (
                        <ul className="mlm-menu">
                          <li className="list-item">
                            <Link onClick={handleNavigation('/mlmdashboard')} className="list-item-link">
                              <span className="list-item-icon">📄</span>
                              <span className="list-item-text">Trades</span>
                            </Link>
                          </li>
                          <li className="list-item">
                            <Link onClick={handleNavigation('/mlmportfolio')} className="list-item-link">
                              <span className="list-item-icon">📄</span>
                              <span className="list-item-text">Portfolio</span>
                            </Link>
                          </li>
                          <li className="list-item">
                            <Link onClick={handleNavigation('/mlmperformance')} className="list-item-link">
                              <span className="list-item-icon">📄</span>
                              <span className="list-item-text">Performance</span>
                            </Link>
                          </li>
                          <li className="list-item">
                            <Link onClick={handleNavigation(`https://bluerock.live/broledger_mlm.php?ud=${userId}&r=${user_type}&mid=${parent_id}`)} className="list-item-link">
                              <span className="list-item-icon">📒</span>
                              <span className="list-item-text">MLM Ledger</span>
                            </Link>
                          </li>
                          <li className="list-item">
                            <Link onClick={handleNavigation(`https://bluerock.live/summaryReport_mlm.php?ud=${userId}&r=${user_type}&mid=${parent_id}`)} className="list-item-link">
                              <span className="list-item-icon">📒</span>
                              <span className="list-item-text">MLM Summary</span>
                            </Link>
                          </li>
                          <li className="list-item">
                            <Link onClick={shareOnWhatsApp} className="list-item-link">
                              <span className="list-item-icon">🔗</span>
                              <span className="list-item-text">Refer MLM Client</span>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </>
                  )}
              {/* { isMLMClient &&
                <li className="list-item">
                  <Link onClick={handleNavigation(`https://bluerock.live/broledger_mlm.php?ud=${userId}&r=${user_type}&mid=${parent_id}`)} className="list-item-link">
                    <span className="list-item-icon">📒</span>
                    <span className="list-item-text">MLM Ledger</span>
                  </Link>
                </li>
              }
              { isMLMClient &&
                <li className="list-item">
                  <Link onClick={handleNavigation(`https://bluerock.live/summaryReport_mlm.php?ud=${userId}&r=${user_type}&mid=${parent_id}`)} className="list-item-link">
                    <span className="list-item-icon">📒</span>
                    <span className="list-item-text">MLM Summary</span>
                  </Link>
                </li>
              }
              { isMLMClient &&
                <li className="list-item">
                  <Link onClick={shareOnWhatsApp} className="list-item-link">
                    <span className="list-item-icon">🔗</span>
                    <span className="list-item-text">Refer MLM Client</span>
                  </Link>
                </li>
              } */}
              <li className="list-item">
                <Link  onClick={handleNavigation('/change-password')} className="list-item-link">
                  <span className="list-item-icon">🔑</span>
                  <span className="list-item-text">Change Password</span>
                </Link>
              </li>
              <li className="list-item">
                <a href={whatsappLink} className="list-item-link" target="_blank" rel="noopener noreferrer">
                  <span className="list-item-icon">📞</span>
                  <span className="list-item-text">Contact Support</span>
                </a>
              </li>
              <li className="list-item">
                <Link onClick={doLogin} className="list-item-link">
                  <span className="list-item-icon">➜</span>
                  <span className="list-item-text">Logout</span>
                </Link>
              </li>
            </ul>
        </div>
        {/* <List>
            <ListItem button onClick={handleNavigation('/')}>
              <ListItemText primary="Watchlist"  style={{fontSize:'18px', fontFamily:'Raleway !important'}}/>
            </ListItem>
            <ListItem button onClick={handleNavigation('/trades')}>
              <ListItemText primary="Trades" />
            </ListItem>
            <ListItem button onClick={handleNavigation('/portfolio')}>
              <ListItemText primary="Portfolio" />
            </ListItem>
            <ListItem button onClick={handleNavigation('/profile')}>
              <ListItemText primary="Profile" />
            </ListItem>
          </List> */}
      </Drawer>
    </>
    
    
  );
}

export default TitleBar;
