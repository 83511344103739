import React from 'react';
import './../TradeCard.css';
import { Delete } from '@mui/icons-material';
import config from '../config';

const MLMTradeCard = ({ trade, refreshTradesAndPortfolio }) => {
  const { orderId, stockId, stockName, orderType, autoCutDate, orderDateTime, exchange, orderPrice, orderQuantity, buy_sell, orderStatus, orderTransactionType } = trade;
  const quantityColor = buy_sell === '1' ? 'green' : 'red';
  //const statusText = orderStatus === '2' ? 'COMPLETE' : 'PENDING';
  const orderTransaction = orderTransactionType === '1' ? 'Open' : 'Close';
  const orderTransactionColor = orderTransactionType === '1' ? 'green' : 'red';
  const userId = Number(localStorage.getItem('userId'));

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const year = date.getFullYear();
    const month = date.toLocaleString('en-US', { month: 'short' });
    return `${day}-${month}-${year}`;
  };

  const cancelOrder = async () => {
    const confirmBox = window.confirm('Are you sure you want to cancel this pending order?');

    if (!confirmBox) {
      return;
    }

    try {
      const response = await fetch(`${config.API_BASE_URL}cancelPendingOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cancelledBy: userId,
          clientId: userId,
          stockId: stockId,
          orderId: orderId,
        }),
      });

      const data = await response.json();

      if (data.success) {
        // console.log(data);
        // window.location.reload();
        alert(data.message);
        refreshTradesAndPortfolio('both');
      } else {
        // Handle the case where the cancelation failed
        console.error('Cancelation failed', data.message);
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('An error occurred:', error);
    }
  };

  const orderTypeText = (orderType, buy_sell) => {
    switch(orderType){
        case "1":
          return "MARKET";
        case "2":
          {
            switch(buy_sell){
              case "1":
                return "BUY LIMIT";
              case "2":
                return "SELL LIMIT";
            }
          }
          //return "LIMIT";
        case "3":
          return "STOP LOSS";
    }
  };

  return (
    <div className="trade-card">
        <div className="d-flex justify-content-between align-items-center">
            <h4 className="trade-scriptName">{stockName}</h4>
            <div className="trade-price">{Number(orderPrice).toFixed(2)}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
            <span className="trade-dateTime">Expiry {formatDate(trade.autoCutDate)}</span>
            <span className='trade-dateTime'>{new Date(orderDateTime).toLocaleString()}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center">
            <div className="trade-body">
                <p className="trade-scriptName">{trade.clientName} ({trade.clientId})</p>
            </div>
            <div className="trade-body">
                <p className={`trade-quantity ${quantityColor}`}>
                    {buy_sell === '1' ? `QTY:${Number(orderQuantity).toFixed(2)}` : `QTY:-${Number(orderQuantity).toFixed(2)}`}
                </p>
                <p style={{ marginRight:'10px' }} className="trade-market">{orderTypeText(orderType, buy_sell)}</p>
                <span className={`trade-transaction ${orderTransactionColor}`}>{orderTransaction}</span>
            </div>
        </div>
            
        
    </div>
  );
};

export default MLMTradeCard;
