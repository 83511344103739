// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { CssBaseline, Container, backdropClasses } from '@mui/material';
// import NavBar from './components/NavBar';
// import TitleBar from './components/TitleBar';
// import { ToastContainer, toast } from 'react-toastify';
// import Watchlist from './pages/Watchlist';
// //import WebSocketComponent from './pages/WebSocketComponent';
// import Trades from './pages/Trades';
// import Portfolio from './pages/Portfolio';
// import Profile from './pages/Profile';
// import Performance from './pages/Performance';
// import BlockedScripts from './pages/BlockedScripts';
// import StockList from "./StockList";
// import { WebSocketProvider, WebSocketContext } from "./WebSocketContext";
// import NavigationNotifier from './NavigationNotifier';
// import './index.css';
// import ChangePassword from './pages/ChangePassword';
// import Login from './pages/Login';

// function App() {
//   const resource = "zerodha"; // Example resource
//   const stockIDs = [
//     // Example stock IDs or subscriptions
//     "CRUDEOILM24JULFUT",
//     "CRUDEOIL24JULFUT"
//   ];
//   const user_id = '689239';
  
//   return (
    
//     <WebSocketProvider style={{ backgroundColor:'#f8f9fa'}} user_id={user_id} resource={resource} stockIDs={stockIDs}>
//       {/* <Router> */}
//         <CssBaseline />
//         <TitleBar />
//         <Container style={{ paddingTop: '64px', paddingBottom: '64px', backgroundColor:'#f8f9fa' }}>
//           <ToastContainer />
//           <NavigationNotifier />
//           <Routes>
//             <Route path="/" element={<StockList />} />
//             <Route path="/login" element={<Login />} />
//             <Route path="/trades" element={<Trades />} />
//             <Route path="/portfolio" element={<Portfolio />} />
//             <Route path="/performance" element={<Performance />} />
//             <Route path="/blocked-scripts" element={<BlockedScripts />} />
//             <Route path="/change-password" element={<ChangePassword />} />
//             {/* <Route path="/profile" element={<Profile />} /> */}
//           </Routes>
//         </Container>
//         <NavBar />
//       {/* </Router> */}
//     </WebSocketProvider>
//   );
// }

// export default App;



// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, Container } from '@mui/material';
import NavBar from './components/NavBar';
import TitleBar from './components/TitleBar';
import { ToastContainer } from 'react-toastify';
import StockList from './StockList';
import Trades from './pages/Trades';
import Portfolio from './pages/Portfolio';
import Performance from './pages/Performance';
import BlockedScripts from './pages/BlockedScripts';
import ChangePassword from './pages/ChangePassword';
import Login from './pages/Login';
import Register from './pages/Register';
import LoginNew from './pages/LoginNew';
//import { AuthProvider, useAuth } from './contexts/AuthContext';
import './index.css';
import NavigationNotifier from './NavigationNotifier';
import { WebSocketProvider } from './WebSocketContext';
import ProtectedRoute from './ProtectedRoute';
import { Outlet } from 'react-router-dom';
import { getToken, storeToken, removeToken } from './utils/auth'; // Adjust path as needed
import AddScript from './pages/AddScript';
import Ledger from './pages/Ledger';
import MLMDashboard from './pages/MLMDashboard';
import MLMPortfolio from './pages/MLMPortfolio';
import MLMPerformance from './pages/MLMPerformance';
import BottomTime from './components/BottomTime';
import RejectionLogs from './pages/RejectionLogs';
import { Helmet } from 'react-helmet';

const Layout = () => {
  //const { isAuthenticated } = useAuth();
  const resource = "zerodha";
  const stockIDs = ["CRUDEOILM24JULFUT", "CRUDEOIL24JULFUT"];
  //const user_id = '689239';
  const user_id = Number(localStorage.getItem('userId'));

  const token = localStorage.getItem('authToken');

  return token ? (
    <WebSocketProvider user_id={user_id} resource={resource} stockIDs={stockIDs}>
      <TitleBar />
      <Container style={{ paddingTop: '90px', paddingBottom: '94px', backgroundColor: '#f8f9fa' }}>
        <Outlet />
      </Container>
      <NavBar />
    </WebSocketProvider>
  ) : (
    <Navigate to="/login" replace />
  );
};

function App() {
  return (
    <>
      <Helmet>
        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </Helmet>
      <CssBaseline />
      <ToastContainer />
      <NavigationNotifier />
      {/* <Router> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route element={<Layout />}>
            <Route path="/addScript" element={<ProtectedRoute element={<AddScript />} />} />
            <Route path="/" element={<ProtectedRoute element={<StockList />} />} />
            <Route path="/trades" element={<ProtectedRoute element={<Trades />} />} />
            <Route path="/portfolio" element={<ProtectedRoute element={<Portfolio />} />} />
            <Route path="/performance" element={<ProtectedRoute element={<Performance />} />} />
            <Route path="/blocked-scripts" element={<ProtectedRoute element={<BlockedScripts />} />} />
            <Route path="/rejection-logs" element={<ProtectedRoute element={<RejectionLogs />} />} />
            <Route path="/change-password" element={<ProtectedRoute element={<ChangePassword />} />} />
            <Route path="/ledger" element={<ProtectedRoute element={<Ledger />} />} />
            <Route path="/mlmdashboard" element={<ProtectedRoute element={<MLMDashboard />} />} />
            <Route path="/mlmportfolio" element={<ProtectedRoute element={<MLMPortfolio />} />} />
            <Route path="/mlmperformance" element={<ProtectedRoute element={<MLMPerformance />} />} />
          </Route>
        </Routes>
      {/* </Router> */}
    </>
  );
}

export default App;

