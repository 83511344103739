import React, { useState, useEffect } from 'react';
import swal from 'sweetalert'; // Ensure you have sweetalert installed
import { useNavigate } from 'react-router-dom';
import './../Login.css';
import config from '../config'; // Assuming you have a config file with API_BASE_URL
import blue_rock_icon from '../assets/blue_rock_icon.png';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    referral: '',
    mobile: '',
    city: '',
  });

  // Fetch referralid from URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referralId = params.get('ud');
    if (referralId) {
      setFormData((prevData) => ({ ...prevData, referral: referralId }));
    }
  }, []);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (formData.password !== formData.confirmPassword) {
    //   alert('Passwords do not match!');
    //   return;
    // }

    try { 
        var url = 'copyClient';

        if(formData.referral != 0){
            url = 'copyClientMLM';
        }
      const response = await fetch(`${config.API_BASE_URL}${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({

            clientName: "'"+formData.username+"'",
            password: "'"+formData.password+"'",
            phoneNumber: "'"+formData.mobile+"'",
            city: "'"+formData.city+"'",
            role: "E",
            referral: formData.referral,
            isMLMClient: formData.referral !== 0 ? 1 : 0
        }),
      });

    //   const resData = await response.json();

    //   if (resData.success) {
    //     alert('Registration successful!');
    //     navigate('/login'); // Redirect to login page after successful registration
    //   } else {
    //     alert('Registration failed: ' + resData.message);
    //   }
    const resData = await response.json();

        if (resData.success) {
            swal({
                title: "Account Successfully Created",
                text: `Your account ID is ${resData.client_id}. To activate this account, please send a message on WhatsApp.`,
                icon: "success",
                buttons: {
                cancel: "Close",
                confirm: "Go to Whatsapp"
                },
            }).then((value) => {
                if (value) {
                    window.location = `https://wa.me/${resData.supportContact}?text=Activate%20Account%20${resData.client_id}`;
                    navigate('/login'); // Redirect to login page after successful registration
                } else {
                    navigate('/login'); // Redirect to login page after successful registration
                }
            });
        } else {
            alert('Registration failed: ' + resData.message);
        }
    } catch (error) {
      alert('Error in registration.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="login-container">
      <div style={{ width: '100%', textAlign: 'center' }}>
        <img src={blue_rock_icon} style={{ maxHeight: '80px', marginBottom: '20px' }} alt="Blue Rock Icon" />
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 style={{marginTop:'-10px'}}>Register</h2>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <label htmlFor="referral">Referral Id (Optional)</label>
          <input
            type="referral"
            id="referral"
            name="referral"
            onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                if (e.target.value.length > 6) {
                    e.target.value = e.target.value.slice(0, 6); // Truncate to 10 digits
                }
            }}
            value={formData.referral}
            onChange={handleChange}
            required
          />
          <label htmlFor="mobile">Mobile</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            // onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Restrict non-numeric characters
            onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                if (e.target.value.length > 10) {
                e.target.value = e.target.value.slice(0, 10); // Truncate to 10 digits
                }
            }}
            required
            pattern="[0-9]{10}" // Accept only 10 digits
            maxLength="10" // Limit input to 10 digits
          />
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={(e) => {
                const value = e.target.value;
                if (/^[a-zA-Z\s]*$/.test(value)) {
                setFormData({ ...formData, city: value });
                }
            }}
            required
            pattern="^[a-zA-Z\s]+$"
          />
          <button type="submit">Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;
